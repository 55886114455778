import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '76, 68, 207',
		'primary-dark': '28, 22, 135',
		'accent': '245, 85, 171',
		'accent-plus': '255, 255, 255',
	},
});
